<template>
  <div class="settled"  :class="{app:isApp}">
		<div v-if="isApp" class="regesit-app">
			<div class="fromDate">
			  <administrators @postKey="postKey" />
			</div>
		</div>
    <div class="regesit" v-else>
      <div class="fromDate">
        <administrators @postKey="postKey" />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "@/server/ajax.js";
import { wxShareConfige, shareWXQQ, isMobile } from "@/utils/wxConfig.js";
const URLS = {
  STATUS: "/api/users/company_station/status",
};
import administrators from "./administrators.vue";

export default {
  name: "settled",
  components: {
    administrators,
  },
  data() {
    return {
      error:false,
			isApp:false,
      id:0,
      keys: {},
      examineMsg: {}, // 平台审核的状态
      message: {}, // 审核状态的状态值
      stepArray: [
        {
          id: 1,
          name: "①管理员注册",
        },
        {
          id: 2,
          name: "②创建企业",
        },
        {
          id: 3,
          name: "③平台审核",
        },
        {
          id: 4,
          name: "④入驻成功",
        },
      ],
    };
  },
  created() {
    this.isApp = isMobile();
    let query = this.$route.query;
    if(query.invited){
      this.id = query.invited;
      this.getPlatData();
    }
  },
	async mounted() { 
		let wxConfig = await wxShareConfige();
		if(this.$route.query.k) {
		  this.$axios.postJSON('/base/invite/detail',{serial_number:this.$route.query.k}).then((res) => {
		    if(res.code == 200) {
					//配置微信分享
					if (wxConfig === "1") {
					  shareWXQQ(res.data.invite_set);
					}
		    }
		  })
		}
	},
  methods: {
    postKey(val) {
      this.keys = val;
      this.status = 2;
    },
    getPlatData(){ //获取运营方邀请数据
      return 1;
      get('/base/invited/detail',{id:this.id}).then(res=>{
        console.log('detail',res);
      }).catch(err=>{

      })
    },
    goto(val) {
      // if(val <= this.status){
      //     this.status = val;
      // }
    },
    isok(val) {
      this.status = 3;
      this.examineMsg = val;
    },
    // 进入重新注册
    registerAgain() {
      this.status = 1;
      this.error = false;
    },
  },
  destroyed() {
    clearInterval(this.times);
  },
};
</script>

<style lang="scss" scoped>
.settled {
  width: 1200px;
  margin: 10px auto;
  margin-top:32px;
  & > div {
    width: 600px;
    margin:0 auto;
    display: block;
  }
  .regesit {
    height: 540px;
    background-color: #fff;
    padding: 30px;
    box-sizing: border-box;
    .titles {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin-bottom: 40px;
    }
    .stepColor {
      color: #4387f8;
      user-select: none;
      &:nth-of-type(odd) {
        cursor: pointer;
      }
    }
    .fromDate {
      padding: 0px 60px;
    }
    .redColor{
      color: red;
    }
  }
}

// 手机端样式
.settled.app{
	font-size: calc(100vw / 750 * 20);
	width:100vw;
	margin:0 1.5em;
	display: block;
	& >div{
		width:100%;
	}
	.regesit-app{
		background-color: #ffffff;
		position: relative;
		top:-2em;
		border-radius: 1em 1em .2em .2em;
		
		.titles{
			display: flex;
			font-size: 1.6em;
			padding:.2em .3em;
			padding-top:1em;
			justify-content: center;
			color:#999999;
			.stepColor{
				color:#4387F8;
			}
			.line{
				margin:0 .5em;
			}
		}
	}
}
</style>
<style lang="scss">
	.app-el-form{
		font-size: calc(100vw/750 * 20);
		// el-form组件代码
		.el-form-item{
			margin-bottom: 3rem;
		}
		.el-form-item__content{
			line-height: 1em;
			font-size: 1.4rem;
		}
		.el-input{
			font-size: 1.4rem;
		}
		.el-input__inner{
			line-height:1;
			font-size: 1.4rem;
			height: 2.5em;
			padding-left:1rem;
		}
		.el-form-item--small .el-form-item__error{
			padding-top:.5rem;
		}
		.el-form-item__error{
			font-size: 1.3rem;
		}
		.el-button{
			border-radius: .2em;
		}
		.el-button--danger{
			line-height: 1.75;
			font-size: 1em;
		}
		.el-checkbox{
			font-size: 1.2rem;
			margin-right: .4em;
		}
		.el-checkbox__inner{
			width: 1.4rem;
			height: 1.4rem;
			&::after{
				width:.5rem;
				height:.7rem;
				border:.2rem solid #FFFFFF;
				border-left:0;
				border-top:0;
				left:.3rem;
			}
		}
	}
</style>
