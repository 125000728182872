<template>
  <el-row :gutter="20">
    <div v-if="isApp" class="app-el-form app-status">
      <el-form
        :rules="rules"
        ref="ruleForm"
        label-position="left"
        :model="fromData"
      >
        <el-form-item prop="mobile">
          <template slot="prepend">+86</template>
          <el-input
            placeholder="请输入手机号码"
            v-model="fromData.mobile"
            class="input-with-select"
            type="text"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <huakuaiApp :mobile="fromData.mobile" @isOk="isOk" ref="huakuai" />
        </el-form-item>
        <el-form-item prop="valid_code">
          <div class="code-box">
            <el-input
              placeholder="请输入验证号码"
              v-model="fromData.valid_code"
              type="text"
            />
            <div class="code-msg-btn">
              <el-button
                @click="getGenerate"
                :disabled="disabled"
                style="width: 100%"
                type="danger"
                plain
                >{{ showWorld }}</el-button
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="real_name">
          <el-input
            placeholder="请输入你的姓名"
            v-model="fromData.name"
            type="text"
          />
        </el-form-item>
        <el-form-item prop="password" class="pass-info">
          <el-input
            placeholder="请输入登录密码"
            v-model="fromData.password"
            show-password
            type="password"
          />
          <p class="info">密码为8-16位字母/数字/符号，不能是纯数字</p>
        </el-form-item>
        <el-form-item prop="email">
          <el-input
            placeholder="请输入可以联系你的电子邮箱(非必填)"
            v-model="fromData.email"
            type="text"
          />
        </el-form-item>
        <div class="agreement-box" v-if="agreeList && agreeList.length > 0">
          <el-checkbox
            v-model="fromData.is_read_agree"
            class="check-box"
          ></el-checkbox>
          <p class="agree-info">
            我已阅读并同意<span v-for="(item, index) in agreeList" :key="index">
              <span class="agree" @click="agreeBtn(item)"
                >《{{ item.title }}》</span
              >
              <tempalte v-if="index < agreeList.length - 1">和</tempalte>
            </span>
          </p>
        </div>
        <div class="btns">
          <el-button
            :disabled="!fromData.is_read_agree"
            @click="submits"
            style="width: 100%; font-size: 1.4em; border-radius: 0.2em"
            type="danger"
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
    <el-form
      v-else
      :rules="rules"
      ref="ruleForm"
      label-position="left"
      :model="fromData"
    >
      <el-col :span="24">
        <el-form-item prop="mobile">
          <template slot="prepend">+86</template>
          <el-input
            placeholder="请输入手机号码"
            v-model="fromData.mobile"
            class="input-with-select"
            type="text"
          >
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-form-item>
          <huakuai
            :mobile="fromData.mobile"
            width="420px"
            @isOk="isOk"
            ref="huakuai"
          />
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <el-form-item prop="valid_code">
          <el-input
            placeholder="请输入验证号码"
            v-model="fromData.valid_code"
            type="text"
          />
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-button
          @click="getGenerate"
          :disabled="disabled"
          style="width: 100%"
          type="danger"
          plain
          >{{ showWorld }}</el-button
        >
      </el-col>
      <el-col :span="24">
        <el-form-item prop="password">
          <el-input
            placeholder="请输入登录密码"
            v-model="fromData.password"
            show-password
          />
          <p class="info">密码为8-16位字母/数字/符号，不能是纯数字</p>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="real_name">
          <el-input
            placeholder="请输入你的姓名"
            v-model="fromData.name"
            type="text"
          />
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item prop="email">
          <el-input
            placeholder="请输入可以联系你的电子邮箱(非必填)"
            v-model="fromData.email"
            type="text"
          />
        </el-form-item>
      </el-col>
      <el-col class="btns" :span="24">
        <el-button
          :disabled="!fromData.is_read_agree"
          @click="submits"
          style="width: 100%"
          type="danger"
          >提交</el-button
        >
      </el-col>
      <el-col :span="24" v-if="agreeList && agreeList.length > 0">
        <div class="agreement-box">
          <el-checkbox
            v-model="fromData.is_read_agree"
            class="check-box"
          ></el-checkbox>
          <p class="agree-info">
            我已阅读并同意<span v-for="(item, index) in agreeList" :key="index">
              <span class="agree" @click="agreeBtn(item)"
                >《{{ item.title }}》</span
              >
              <tempalte v-if="index < agreeList.length - 1">和</tempalte>
            </span>
          </p>
        </div>
      </el-col>
    </el-form>
  </el-row>
</template>

<script>
import {
  validatePhoneTwo,
  validatePassword,
  validateEMail,
} from "@/utils/validate.js";
import { post, postJSON } from "@/server/ajax.js";
import { isMobile } from "@/utils/wxConfig.js";
const URL = {
  GENERATE: "/api/captcha/generate", // 获取验证码接口
  SEND: "/api/captcha/send", //验证验证码
  ADMINREG: "/api/users/company_station/adminreg", // 管理员注册接口
};
import huakuai from "@/views/login/loginHome/huakuai.vue";
import huakuaiApp from "@/views/login/loginHome/huakuaiApp.vue";
export default {
  props: ["model"],
  components: {
    huakuai,
    huakuaiApp,
  },
  data() {
    let rules = {
      mobile: [
        { required: true, validator: validatePhoneTwo, trigger: "blur" },
      ],
      valid_code: [
        { required: true, message: "请输入验证号码", trigger: "blur" },
      ],
      password: [
        { required: true, validator: validatePassword, trigger: "blur" },
      ],
      name: [{ message: "请输入你的姓名", trigger: "blur" }],
      email: [{ validator: validateEMail, trigger: "blur" }],
    };
    return {
      dialogVisible: false,
      isApp: false,
      n: 60,
      showMessage: "点击获取",
      disabled: true, //获取验证码是否禁用
      img: {
        b64s: "",
        captcha_id: "",
      },
      regestCode: "",
      is_valid: false,
      fromData: {
        mobile: "", //电话号码
        valid_code: "", // 验证号码
        password: "", //密码
        name: "", // 姓名
        email: "", // 电子邮箱
        is_read_agree: false, // 勾选
      },
      times: null, // 定时器
      rules,
      saveData: {},
      disable: false,
      n: 60,
      showWorld: "点击获取",
      agreeList: [],
    };
  },
  created() {
    this.isApp = isMobile();
    this.getAgreeList();
  },
  methods: {
    agreeBtn(row) {
      let routeUrl = this.$router.resolve({
        path: "/register/agreement",
        query: {
          id: row.id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    getAgreeList() {
      //入驻协议
      this.$axios
        .get("/setting/agreement/station_list")
        .then((res) => {
          if (res.code == 200) {
            let data = res.data;
            if (data && data.length > 0) {
              this.agreeList = res.data;
            } else {
              this.fromData.is_read_agree = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isOk(val) {
      if (val.isOk) {
        this.disabled = false;
        this.saveData = val;
        this.is_valid = true;
      }
    },
    // 获取验证码
    getGenerate() {
      let val = this.saveData;
      if (!this.is_valid) {
        this.$refs.huakuai.resets();
        return this.$message.error("请向右滑动滑块验证");
      }
      let parma = {
        mobile: Number(this.fromData.mobile),
        first: {
          x: val.data1.x,
          y: val.data1.y,
          time: val.data1.time,
        },
        second: {
          x: val.data2.x,
          y: val.data2.y,
          time: val.data2.time,
        },
      };
      this.$axios.postJSON("/base/staff/register/send_code", parma).then(
        (res) => {
          if (res.code == 200) {
            this.$message.success("获取成功");
            const that = this;
            this.timers = setInterval(() => {
              that.n--;
              if (that.n < 1) {
                clearInterval(that.timers);
                that.showWorld = "点击获取验证码";
                that.n = 60;
                that.is_valid = false;
                that.disabled = false;
              } else {
                that.showWorld = that.n + "秒后再次获取";
                that.disabled = true;
              }
            }, 1000);
          }
        },
        (err) => {
          this.$refs.huakuai.resets();
        }
      );
    },
    // 提交下一步
    submits() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.fromData));
          params.is_read_agree = "true";
          let query = this.$route.query;
          if (params.mobile) {
            params.mobile = Number(params.mobile);
          }
          if (params.valid_code) {
            params.mobile_verity_code = params.valid_code;
          }
          if (query.k) {
            params.invite_serial_number = query.k;
          }
          postJSON("/base/staff/register", params).then(
            (res) => {
              console.log(res);
              if (res.code == 200) {
                this.$message.success("注册成功，请登录");
                if (process.env.NODE_ENV === "production") {
                  sessionStorage.removeItem("AccessToken");
                } else {
                  localStorage.removeItem("AccessToken");
                }
                setTimeout(() => {
                  this.$router.push("/login");
                }, 500);
              }
            },
            (err) => {
              this.$message({
                message: err.msg,
                type: "warning",
              });
            }
          );
        } else {
          this.$message({
            message: "请填写完整的信息",
            type: "warning",
          });
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.times);
  },
};
</script>

<style lang="scss" scoped>
.konggefu {
  width: 6.5px;
  display: inline-block;
}
.agreement-box {
  display: flex;
  .check-box {
    margin-right: 8px;
  }
  .agree-info {
    color: #666666;
  }
}
.agree {
  color: #f72626;
  cursor: pointer;
}
/deep/ .el-input__inner:focus {
  border-color: red !important;
}
.imgsB {
  margin-bottom: 30px;
  text-align: center;
}
.imgsA {
  text-align: center;
}
.btns {
  margin-top: 10px;
  margin-bottom: 10px;
}
.info {
  color: #999999;
  font-size: 12px;
  line-height: 30px;
}

.app-status {
  padding: 2em 1.5em 2em;
  .code-box {
    display: flex;
    .code-msg-btn {
      width: 12em;
      margin-left: 0.5em;
    }
  }
  .pass-info .info {
    margin-top: 0.75rem;
    font-size: 1.2rem;
    color: #999999;
  }
  .agreement-box {
    margin-top: 1em;
    font-size: 1.4em;
  }
  .btns {
    // position: fixed;
    // bottom:2em;
    // left:3em;
    margin-top: 1.5rem;
    width: 32.5em;
  }
}
</style>
